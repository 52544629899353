<script setup lang="ts">
import LxcForm from "@lxc/app-device-common/src/components/form/LxcForm.vue";
import dayjs from "dayjs";

// The date can be null due to `lxc-input` usage. When the date picker is opened but a date not chosen, the date is set to null.
export interface CampaignDateRangeI {
  startAt: Date | undefined;
  endAt: Date | undefined;
}

interface Props {
  modelValue?: CampaignDateRangeI;
  canEdit: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: undefined,
});

const emit = defineEmits(["update:modelValue"]);

const { t } = useI18n();

const datePickerPlaceholder = "-- / -- / --   -- : --";
const minIntervalBetweenStartAndEndDateInDays = 1;

// The width is a magic number which match the potential date format and the
// placeholder length.
const datePickerClasses = ["!w-44", "text-gray-700"];

const formRef: Ref<typeof LxcForm | undefined> = ref();
const form = computed({
  get() {
    return props.modelValue;
  },
  set(form: CampaignDateRangeI) {
    emit("update:modelValue", form);
  },
});

const isStartDateValid = (_: unknown, __: unknown, callback: unknown) => {
  if (
    props.modelValue?.startAt &&
    props.modelValue.startAt?.getTime() < Date.now()
  ) {
    callback(new Error(t("campaign.dateInferiorToToday")));
  }
  callback();
};

const isEndDateValid = (_: any, __: any, callback: any) => {
  if (
    props.modelValue?.startAt &&
    props.modelValue?.endAt &&
    dayjs(form.value?.startAt)
      .add(minIntervalBetweenStartAndEndDateInDays, "days")
      .toDate()
      .getTime() > props.modelValue.endAt?.getTime()
  ) {
    callback(
      new Error(
        t("campaign.dateInferiorToDate", {
          date: dayjs(form.value?.startAt)
            .add(minIntervalBetweenStartAndEndDateInDays, "days")
            .format(t("campaign.dateFormat")),
        }),
      ),
    );
  }
  callback();
};

const rules = {
  startAt: [
    {
      type: "date",
      required: true,
      message: t(
        "campaign.funnel.campaignPlanification.executionDates.validation.plannedStartAt",
      ),
      trigger: "update:model-value",
    },
    { validator: isStartDateValid, trigger: "update:model-value" },
  ],
  endAt: [
    {
      type: "date",
      required: true,
      message: t(
        "campaign.funnel.campaignPlanification.executionDates.validation.plannedEndAt",
      ),
      trigger: "update:model-value",
    },
    { validator: isEndDateValid, trigger: "update:model-value" },
  ],
};

// The end of a campaign end date must be at least one day after the campaign started date.
const defaultStartDate = new Date(Date.now());
const endAtMinDate = computed(() =>
  form.value?.startAt !== undefined && form.value?.startAt !== null
    ? dayjs(form.value.startAt)
        .add(minIntervalBetweenStartAndEndDateInDays, "days")
        .toDate()
    : dayjs(defaultStartDate)
        .add(minIntervalBetweenStartAndEndDateInDays, "days")
        .toDate(),
);

// When the start at date is updated, the end at date should be reset if the interval between start and end date is not enough.
watch(
  () => form.value?.startAt,
  (newValue) => {
    if (
      newValue !== undefined &&
      newValue !== null &&
      form.value?.endAt !== undefined &&
      form.value?.endAt !== null &&
      dayjs(newValue)
        .add(minIntervalBetweenStartAndEndDateInDays, "days")
        .toDate() > form.value?.endAt
    ) {
      form.value.endAt = undefined;
    }
  },
  { deep: true },
);

defineExpose({
  validate: async () =>
    Promise.resolve(formRef.value?.validate().catch(() => false)),
});
</script>

<template>
  <h4 class="mb-4">
    {{ t("campaign.funnel.campaignPlanification.title") }}
  </h4>
  <lxc-form ref="formRef" :model="form" :rules="rules">
    <div class="flex gap-8 h-full">
      <lxc-form-item
        :label="
          t(
            'campaign.funnel.campaignPlanification.executionDates.plannedStartAt',
          )
        "
        prop="startAt"
        class="w-52"
      >
        <lxc-input
          v-model="form.startAt"
          type="datetime"
          :class="datePickerClasses"
          :placeholder="datePickerPlaceholder"
          prefix-class="mr-2"
          prefix-read-only
          :min-date="defaultStartDate"
          :disabled="!canEdit"
        />
      </lxc-form-item>
      <lxc-form-item
        :label="
          t('campaign.funnel.campaignPlanification.executionDates.endDate')
        "
        prop="endAt"
        class="w-52"
      >
        <lxc-input
          :key="endAtMinDate"
          v-model="form.endAt"
          type="datetime"
          :class="datePickerClasses"
          :placeholder="datePickerPlaceholder"
          prefix-class="mr-2"
          prefix-read-only
          :min-date="endAtMinDate"
          :disabled="!canEdit"
        />
      </lxc-form-item>
    </div>
  </lxc-form>
</template>
