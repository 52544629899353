<script setup lang="ts">
/**
 * Display the information of a firmware
 * Fetch the firmware if compatible hardware & software versions are not defined in firmware
 * Trigger update event after fetched
 */
import type { FirmwareI } from "@lxc/app-device-types";
import firmwareService from "~/services/firmware.service";
import LxcError from "~/utils/LxcError";
import ILxcInfo from "~icons/lxc/info";

const props = defineProps<{
  firmware?: FirmwareI | null;
}>();

const { t } = useI18n();

const emit = defineEmits(["update:firmware"]);

const error: Ref<LxcError | undefined | null> = ref(null);
const isLoading = ref<boolean>(true);

const computedFirmware = computed({
  get(): FirmwareI | undefined | null {
    return props.firmware;
  },
  set(firmware: FirmwareI | undefined | null) {
    emit("update:firmware", firmware);
  },
});

const hardwareVersions = computed(() =>
  computedFirmware.value?.hardwareVersions?.join(", "),
);
const firmwareVersions = computed(() =>
  computedFirmware.value?.firmwareVersions?.join(", "),
);

/**
 * Fetch the firmware
 */
const fetchFirmware = async () => {
  isLoading.value = true;

  // Fetch the firmware only if the attributes firmwareVersions & hardwareVersions are not presents
  if (
    props.firmware &&
    (props.firmware.firmwareVersions === undefined ||
      props.firmware.hardwareVersions === undefined)
  ) {
    const response = await firmwareService.getFirmwareByUuid(
      props.firmware.uuid,
    );

    if (LxcError.check(response)) {
      computedFirmware.value = null;
      error.value = response;
    } else {
      computedFirmware.value = response;
    }
  }

  isLoading.value = false;
};

watch(() => props.firmware, fetchFirmware);

onMounted(fetchFirmware);
</script>

<template>
  <div v-if="isLoading || computedFirmware || error" class="sticky bottom-0">
    <lxc-container
      :is-loading="isLoading"
      :error="error"
      :px="0"
      :py="0"
      :loader-size="14"
    >
      <lxc-card body-class="!p-4">
        <div class="flex align-middle">
          <div class="border rounded-lg border-gray-300 p-2.5 self-center">
            <ILxcInfo class="text-gray-500" height="20" width="20" />
          </div>
          <div class="ms-3">
            <div class="font-semibold mb-1 text-gray-700">
              {{ t("firmware.firmwareInfo.compatibility.title") }}
            </div>
            <div class="font-normal text-gray-600">
              {{ t("firmware.firmwareInfo.compatibility.description") }}
            </div>
          </div>
        </div>
        <div class="flex gap-5 mt-3 mb-1">
          <div class="flex-1">
            <p class="text-gray-700">
              {{ t("firmware.firmwareInfo.equipmentTypeAttached") }}
            </p>
            <lxc-input
              v-model="computedFirmware.range"
              class="!bg-gray-50"
              label=""
              type="text"
              disabled
            />
          </div>
          <div class="flex-1">
            <p class="text-gray-700">
              {{ t("firmware.firmwareInfo.compatibleHardwareVersions") }}
            </p>
            <lxc-input
              v-model="hardwareVersions"
              class="!bg-gray-50"
              label=""
              type="text"
              disabled
            />
          </div>
          <div class="flex-1">
            <p class="text-gray-700">
              {{ t("firmware.firmwareInfo.compatibleFirmwareVersions") }}
            </p>
            <lxc-input
              v-model="firmwareVersions"
              class="!bg-gray-50"
              label=""
              type="text"
              disabled
            />
          </div>
        </div>
      </lxc-card>
    </lxc-container>
  </div>
</template>
