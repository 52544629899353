<script setup lang="ts">
import { OperationModelI, OperationModelType } from "@lxc/app-device-types";
import LxcCampaignFunnelStep3DateRange, {
  CampaignDateRangeI,
} from "~/components/campaigns/campaignForm/campaignOperationMgrFunnel/campaignFunnelStep3/LxcCampaignFunnelStep3DateRange.vue";
import LxcCampaignFunnelStep3InclusionPeriods, {
  CampaignInclusionPeriodsI,
} from "~/components/campaigns/campaignForm/campaignOperationMgrFunnel/campaignFunnelStep3/LxcCampaignFunnelStep3InclusionPeriods.vue";
import { CUSTOM_INPUT_PARAMETERS_NAMES } from "~/constants/campaignOperationMgr";

export interface CampaignFunnelStep3 {
  dateRange: CampaignDateRangeI;
  inclusionPeriods: CampaignInclusionPeriodsI;
}

interface Props {
  modelValue: CampaignFunnelStep3;
  canEdit: boolean;
  operationModels: OperationModelI[];
  operationModelType?: OperationModelType;
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: undefined,
  operationModelType: undefined,
});

const emit = defineEmits(["update:modelValue"]);

const campaignFunnelStep3 = computed({
  get() {
    return props.modelValue;
  },
  set(campaignFunnelStep3?: CampaignFunnelStep3) {
    emit("update:modelValue", campaignFunnelStep3);
  },
});

const campaignFunnelStep3DateRangeRef: Ref<
  typeof LxcCampaignFunnelStep3DateRange | undefined
> = ref();
const campaignFunnelStep3InclusionPeriodsRef: Ref<
  typeof LxcCampaignFunnelStep3InclusionPeriods | undefined
> = ref();
const windowWidth = ref(window.innerWidth);

const onResize = () => {
  windowWidth.value = window.innerWidth;
};

// Magic numbers are used here to make the component responsive.
const containerClass = computed(() => {
  let containerClass = "w-full";
  if (windowWidth.value > 1700) {
    containerClass = "w-3/5";
  } else if (windowWidth.value > 1400) {
    containerClass = "w-4/5";
  }
  return containerClass;
});

// display the inclusion periods if at least one operation models of the selected operation type can manage them
const displayInclusionPeriods = computed(() => {
  for (const operationModel of props.operationModels) {
    if (operationModel.type === props.operationModelType) {
      for (const customInputParameter of operationModel.customInputParameters) {
        if (
          customInputParameter.name ===
          CUSTOM_INPUT_PARAMETERS_NAMES.INCLUSION_PERIODS
        ) {
          return true;
        }
      }
    }
  }

  return false;
});

onMounted(() => {
  window.addEventListener("resize", onResize);
});

onUnmounted(() => {
  window.removeEventListener("resize", onResize);
});

defineExpose({
  validate: async () =>
    Promise.all([
      campaignFunnelStep3DateRangeRef.value?.validate().catch(() => false),
      campaignFunnelStep3InclusionPeriodsRef.value
        ?.validate()
        .catch(() => false),
    ]),
});
</script>

<template>
  <div :class="containerClass">
    <LxcCampaignFunnelStep3DateRange
      ref="campaignFunnelStep3DateRangeRef"
      v-model="campaignFunnelStep3.dateRange"
      :can-edit="canEdit"
    />
    <LxcCampaignFunnelStep3InclusionPeriods
      v-if="displayInclusionPeriods"
      ref="campaignFunnelStep3InclusionPeriodsRef"
      v-model="campaignFunnelStep3.inclusionPeriods"
      :can-edit="canEdit"
    />
  </div>
</template>
