import type { ArrayDelta } from "~/types/arrayDelta";

export function getArrayDelta(
  initial: unknown[],
  final: unknown[],
): ArrayDelta {
  return {
    added: final.filter((item) => !initial.includes(item)),
    deleted: initial.filter((aItem) => !final.includes(aItem)),
  };
}
