<script setup lang="ts">
import { useSerialize } from "@lxc/app-device-common";
import type { KeystoreCSRRequestI } from "@lxc/app-device-types";
import { CACertificateItemType } from "@lxc/app-device-types";
import type { Rules } from "async-validator";
import { HttpStatusCode } from "axios";
import { type Ref, ref, type WritableComputedRef } from "vue";
import { useExportCertificateAsString } from "~/composables/useCaCertificates";
import { VALIDATION_REGEXP } from "~/constants/constants";
import keystoreService from "~/services/keystore.service";
import type { KeystoreCSRRequestForm } from "~/types";
import LxcError from "~/utils/LxcError";
import {
  NotificationKey,
  showNotificationError,
  showNotificationSuccess,
} from "~/utils/notifications-tools";
import { getValuesFromUniqueLabels } from "~/utils/unique-label-tools";

const { t } = useI18n();
const serialize = useSerialize();
const { exportCertificateAsString } = useExportCertificateAsString();

const props = defineProps<{
  disabled?: false;
  isTagsLoading: boolean;
  sideCanvasShown: boolean;
  tags?: Array<string> | null;
  tagsError?: LxcError | null;
}>();

const isLoading: Ref<boolean> = ref(false);

const emit = defineEmits(["save", "update:sideCanvasShown"]);

const defaultCSRRequestForm: KeystoreCSRRequestForm = {
  alias: "",
  subject: {
    cn: "",
    email: "",
    serialNumber: undefined,
    organizationUnit: undefined,
    organization: undefined,
    locality: undefined,
    region: undefined,
    country: undefined,
  },
  tags: [],
};

const formSideCanvasShown: WritableComputedRef<boolean> = computed({
  get() {
    return props.sideCanvasShown;
  },
  set(sideCanvasShown: boolean) {
    emit("update:sideCanvasShown", sideCanvasShown);
  },
});
const csrRequestForm: Ref<KeystoreCSRRequestForm> = ref<KeystoreCSRRequestForm>(
  {
    ...defaultCSRRequestForm,
    subject: { ...defaultCSRRequestForm.subject },
    tags: [...defaultCSRRequestForm.tags],
  },
);
const defaultCSRRequestFormStringified = serialize(defaultCSRRequestForm);
const csrRequestFormRef = ref();
const edited = computed(() => {
  const strigifiedForm = serialize(csrRequestForm.value);
  return strigifiedForm !== defaultCSRRequestFormStringified;
});

// Certificate rules
const rules = computed(() => {
  const rulesConfig: Rules = {
    alias: [
      {
        required: true,
        message: t("certificates.validation.alias.required"),
        type: "string",
        whitespace: false,
      },
      {
        type: "string",
        message: t("certificates.validation.alias.invalid"),
        pattern: VALIDATION_REGEXP.CERTIFICATE_ALIAS,
      },
    ],
    "subject.cn": [
      {
        required: true,
        message: t("certificates.validation.cn"),
        type: "string",
        whitespace: false,
      },
    ],
    "subject.email": [
      {
        required: true,
        message: t("certificates.validation.email"),
        whitespace: false,
      },
      {
        type: "email",
        message: t("input.error.invalidFormat"),
      },
    ],
  };

  return rulesConfig;
});

function setCSRRequestForm() {
  Object.assign(csrRequestForm.value, defaultCSRRequestForm, {
    subject: { ...defaultCSRRequestForm.subject },
    tags: [...defaultCSRRequestForm.tags],
  });
  setTimeout(csrRequestFormRef.value?.clearValidate, 0);
}

async function saveForm(): Promise<void> {
  isLoading.value = true;
  csrRequestForm.value.alias = csrRequestForm.value.alias.trim();

  const csrRequest: KeystoreCSRRequestI = {
    ...csrRequestForm.value,
    tags: getValuesFromUniqueLabels(csrRequestForm.value.tags),
  };

  const response =
    await keystoreService.generateCertificateSigningRequest(csrRequest);

  if (LxcError.check(response)) {
    if (response.status === HttpStatusCode.Conflict) {
      showNotificationError(
        t("notification.error.keystore.aliasAlreadyUsed.title"),
        t("notification.error.keystore.aliasAlreadyUsed.message"),
        false,
      );
    } else {
      response.notify(NotificationKey.saveError);
    }
  } else {
    if (response.csr) {
      exportCertificateAsString(
        response.csr,
        response.alias ?? "csr",
        CACertificateItemType.CSR,
      );
      showNotificationSuccess(t(NotificationKey.saveSuccess));
      close();
      setCSRRequestForm();
      emit("save");
    } else {
      showNotificationError(
        "error.BAD_PARAMETER.title",
        "error.BAD_PARAMETER.details.LXCONNECT.CERTIFICATEAUTHORITY.CERTIFICATE_BAD_PARAMETER_VALUE",
      );
    }
  }

  isLoading.value = false;
}

async function validate(): Promise<boolean> {
  return await csrRequestFormRef.value?.validate().catch(() => false);
}

const onSubmit = async (): Promise<boolean> => {
  const dataValid = await validate();

  if (dataValid) {
    await saveForm();
  }

  return dataValid;
};

function close() {
  formSideCanvasShown.value = false;
}

const onCancel = () => {
  setCSRRequestForm();
  close();
};
</script>

<template>
  <lxc-side-canvas
    v-model:show="formSideCanvasShown"
    size="1/3"
    :header="t('certificates.keystore.generateCSR')"
    :close-tooltip="t('button.close')"
    @discard="onCancel"
  >
    <lxc-form
      ref="csrRequestFormRef"
      :model="csrRequestForm"
      :rules="rules"
      @submit.prevent="onSubmit"
    >
      <lxc-keystore-generic-generation-form
        v-model="csrRequestForm"
        :disabled="false"
        :edition="false"
        :is-tags-loading="isTagsLoading"
        :tags="tags"
        :tags-error="tagsError"
      />
    </lxc-form>

    <template #footer>
      <div class="grid grid-cols-[max-content_auto] gap-4">
        <lxc-button
          html-type="button"
          type="secondary"
          :title="t('button.close')"
          @click="onCancel"
        >
          {{ t("button.close") }}
        </lxc-button>
        <lxc-button
          html-type="submit"
          :disabled="!edited || isLoading"
          :title="t('certificates.keystore.generateCSR')"
          @click="onSubmit"
        >
          {{ t("certificates.keystore.generateCSR") }}
        </lxc-button>
      </div>
    </template>
  </lxc-side-canvas>
</template>
