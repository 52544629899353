<script setup lang="ts">
import ILxcQuestionCircle from "~icons/lxc-custom/question-circle";
import ILxcCalendar from "~icons/lxc/calendar";
import ILxcPlusCircle from "~icons/lxc/plus-circle";
import ILxcTrash2 from "~icons/lxc/trash-2";

interface InclusionPeriodOptionI {
  label: string;
  value: string;
}

export interface CampaignInclusionPeriodI {
  day: string;
  start: string;
  end: string;
}

export interface CampaignInclusionPeriodsI {
  periods: CampaignInclusionPeriodI[];
}

interface Props {
  modelValue?: CampaignInclusionPeriodsI;
  canEdit: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: undefined,
});

const emit = defineEmits(["update:modelValue"]);

const { t } = useI18n();

const hourPickerWidthInRem = 6.5;
const hourPickerPlaceholder = "-- : --";
const maxInclusionPeriodsInCampaign = 20;
const minSpanTimeBetweenTwoPeriodsInMilliseconds = 15 * 60 * 1000;

// The widths are fixed to force all the element in the vue to be aligned even
// if an invalid form is chosen by the user. They are a magic numbers which
// match the potential day/hour format and the placeholder lengths.
const dayPickerClasses = ["w-72", "text-gray-700", "text-sm", "font-semibold"];
const hourPickerClasses = ["w-44", "!pb-0"];

const formRef: Ref<HTMLElement | undefined> = ref();
const form: ComputedRef<CampaignInclusionPeriodsI> = computed({
  get() {
    return props.modelValue;
  },
  set(form: CampaignInclusionPeriodsI) {
    emit("update:modelValue", form);
  },
});

const isStartValid = (rule: any, value: any, callback: any) => {
  // Get index of the start field
  const index = rule.field.split("[")[1].split("]")[0];
  // Set the value of start field in milliseconds
  const [valueHour, valueMinutes] = value.split(":");
  const valueMilliseconds = new Date().setHours(valueHour, valueMinutes);
  // Get the value of the end and set in milliseconds
  const [endHour, endMinutes] = form.value.periods[index].end.split(":");
  const endMilliseconds = new Date().setHours(
    parseInt(endHour),
    parseInt(endMinutes),
  );

  if (
    valueMilliseconds + minSpanTimeBetweenTwoPeriodsInMilliseconds >
    endMilliseconds
  ) {
    callback(
      new Error(
        t(
          "campaign.funnel.campaignPlanification.inclusionPeriods.validation.range",
        ),
      ),
    );
  } else {
    callback();
  }
};

const isEndValid = (rule: any, value: any, callback: any) => {
  // Get index of the end field
  const index = rule.field.split("[")[1].split("]")[0];
  // Set the value of end field in milliseconds
  const [valueHour, valueMinutes] = value.split(":");
  const valueMilliseconds = new Date().setHours(valueHour, valueMinutes);
  // Get the value of the start and set in milliseconds
  const [startHour, startMinutes] = form.value.periods[index].start.split(":");
  const startMilliseconds = new Date().setHours(
    parseInt(startHour),
    parseInt(startMinutes),
  );

  if (
    valueMilliseconds <
    startMilliseconds + minSpanTimeBetweenTwoPeriodsInMilliseconds
  ) {
    callback(
      new Error(
        t(
          "campaign.funnel.campaignPlanification.inclusionPeriods.validation.range",
        ),
      ),
    );
  } else {
    callback();
  }
};

const rules = computed(() => {
  const formRules: Record<string, unknown[]> = {};
  for (const index in form.value.periods) {
    formRules[`periods[${index}].day`] = [
      {
        type: "string",
        required: true,
        message: t(
          "campaign.funnel.campaignPlanification.inclusionPeriods.validation.day",
        ),
        trigger: "update:model-value",
      },
    ];
    formRules[`periods[${index}].start`] = [
      {
        type: "string",
        required: true,
        message: t(
          "campaign.funnel.campaignPlanification.inclusionPeriods.validation.start",
        ),
        trigger: "update:model-value",
      },
      {
        validator: isStartValid,
        trigger: "update:model-value",
      },
    ];
    formRules[`periods[${index}].end`] = [
      {
        type: "string",
        required: true,
        message: t(
          "campaign.funnel.campaignPlanification.inclusionPeriods.validation.end",
        ),
        trigger: "update:model-value",
      },
      {
        validator: isEndValid,
        trigger: "update:model-value",
      },
    ];
  }

  return formRules;
});

const inclusionPeriodsWeekOptions: InclusionPeriodOptionI[] = [
  {
    value: "MONDAY",
    label: t(
      "campaign.funnel.campaignPlanification.inclusionPeriods.days.monday",
    ),
  },
  {
    value: "TUESDAY",
    label: t(
      "campaign.funnel.campaignPlanification.inclusionPeriods.days.tuesday",
    ),
  },
  {
    value: "WEDNESDAY",
    label: t(
      "campaign.funnel.campaignPlanification.inclusionPeriods.days.wednesday",
    ),
  },
  {
    value: "THURSDAY",
    label: t(
      "campaign.funnel.campaignPlanification.inclusionPeriods.days.thursday",
    ),
  },
  {
    value: "FRIDAY",
    label: t(
      "campaign.funnel.campaignPlanification.inclusionPeriods.days.friday",
    ),
  },
];
const inclusionPeriodsWeekendOptions: InclusionPeriodOptionI[] = [
  {
    value: "SATURDAY",
    label: t(
      "campaign.funnel.campaignPlanification.inclusionPeriods.days.saturday",
    ),
  },
  {
    value: "SUNDAY",
    label: t(
      "campaign.funnel.campaignPlanification.inclusionPeriods.days.sunday",
    ),
  },
];
const inclusionPeriodsOptions = [
  ...inclusionPeriodsWeekOptions,
  ...inclusionPeriodsWeekendOptions,
];

const addEmptyInclusionPeriod = () => {
  form.value.periods.push({
    day: "",
    start: "",
    end: "",
  });
};

const removeInclusionPeriod = (index: number) => {
  if (form.value.periods.length > index) {
    form.value.periods.splice(index, 1);
  }
};

function compareArrays<T>(arr1: T[], arr2: T[]): boolean {
  return JSON.stringify(arr1.sort()) === JSON.stringify(arr2.sort());
}

const isBusinessHoursPresetSelected = computed(() => {
  return compareArrays(
    form.value.periods,
    createBusinessHoursInclusionPeriodsPreset(),
  );
});

const isWeekendPresetSelected = computed(() => {
  return compareArrays(
    form.value.periods,
    createWeekendInclusionPeriodsPreset(),
  );
});

const resetInclusionPeriods = () => {
  form.value.periods = [];
};

const createInclusionPeriodsPreset = (
  inclusionPeriodOption: InclusionPeriodOptionI[],
  start: string,
  end: string,
): CampaignInclusionPeriodI[] => {
  const newPeriods = [];
  for (const day of inclusionPeriodOption) {
    newPeriods.push({
      day: day.value,
      start,
      end,
    });
  }
  return newPeriods;
};

const createBusinessHoursInclusionPeriodsPreset = () => {
  return createInclusionPeriodsPreset(
    inclusionPeriodsWeekOptions,
    "09:00",
    "18:00",
  );
};

const onBusinessHoursPresetSelected = () => {
  if (!isBusinessHoursPresetSelected.value) {
    form.value.periods = createBusinessHoursInclusionPeriodsPreset();
  } else {
    resetInclusionPeriods();
  }
};

const createWeekendInclusionPeriodsPreset = () => {
  return createInclusionPeriodsPreset(
    inclusionPeriodsWeekendOptions,
    "00:00",
    "23:59",
  );
};

const onWeekendPresetSelected = () => {
  if (!isWeekendPresetSelected.value) {
    form.value.periods = createWeekendInclusionPeriodsPreset();
  } else {
    resetInclusionPeriods();
  }
};

defineExpose({
  validate: async () =>
    Promise.resolve(formRef.value?.validate().catch(() => false)),
});
</script>

<template>
  <div class="flex justify-between">
    <h4 class="mb-4">
      {{ t("campaign.funnel.campaignPlanification.inclusionPeriods.title") }}
    </h4>
    <p class="text-black/50 font-semibold">
      {{ t("campaign.funnel.campaignPlanification.inclusionPeriods.subTitle") }}
    </p>
  </div>
  <lxc-card body-class="!p-4">
    <lxc-information class="!my-0 text-gray-600">
      <template #component>
        <lxc-info-sign>
          <ILxcQuestionCircle
            width="1.25rem"
            height="1.25rem"
            view-box="0 0 1.25rem 1.25rem"
          />
        </lxc-info-sign>
      </template>
      <template #body>
        <p class="!mt-0">
          {{
            t(
              "campaign.funnel.campaignPlanification.inclusionPeriods.informations.period",
            )
          }}
        </p>
        <p class="!mb-0">
          {{
            t(
              "campaign.funnel.campaignPlanification.inclusionPeriods.informations.defaultValue",
            )
          }}
        </p>
      </template>
    </lxc-information>
  </lxc-card>
  <lxc-form ref="formRef" :model="form" :rules="rules">
    <div class="flex space-x-4 mt-4">
      <lxc-button
        html-type="button"
        :type="isBusinessHoursPresetSelected ? 'primary' : 'secondary'"
        :title="
          t(
            'campaign.funnel.campaignPlanification.inclusionPeriods.presets.businessHours',
          )
        "
        class="flex-1 text-xs !p-1 font-medium"
        @click="onBusinessHoursPresetSelected"
      >
        <span>
          {{
            t(
              "campaign.funnel.campaignPlanification.inclusionPeriods.presets.businessHours",
            )
          }}
        </span>
      </lxc-button>
      <lxc-button
        html-type="button"
        :type="isWeekendPresetSelected ? 'primary' : 'secondary'"
        :title="
          t(
            'campaign.funnel.campaignPlanification.inclusionPeriods.presets.weekend',
          )
        "
        class="flex-1 text-xs !p-1 font-medium truncated"
        @click="onWeekendPresetSelected"
      >
        <span>
          {{
            t(
              "campaign.funnel.campaignPlanification.inclusionPeriods.presets.weekend",
            )
          }}
        </span>
      </lxc-button>
    </div>
    <div
      v-if="canEdit && form.periods.length === 0"
      class="border rounded-lg border-primary p-4 mt-4"
      @click="addEmptyInclusionPeriod"
    >
      <p class="text-primary flex gap-4 hover:cursor-pointer my-0">
        <ILxcPlusCircle />
        <span class="font-semibold self-center">{{
          t("campaign.funnel.campaignPlanification.inclusionPeriods.addADay")
        }}</span>
      </p>
    </div>
    <div class="mt-4">
      <div
        v-for="(inclusionPeriod, index) in form.periods"
        :key="inclusionPeriod.day + index"
        class="mt-2 bg-primary-50 py-1.5 px-4 rounded-lg border-2 border-primary-50 flex justify-between focus:border-2 focus:border-primary-600 focus-within:border-2 focus-within:border-primary-600 hover:border-2 hover:border-primary-600"
        tabindex="0"
      >
        <div class="flex justify-around w-full">
          <lxc-form-item class="!pb-0" :prop="`periods[${index}].day`">
            <lxc-select
              v-model="inclusionPeriod.day"
              :class="dayPickerClasses"
              :placeholder="
                t(
                  'campaign.funnel.campaignPlanification.inclusionPeriods.selectPlaceholder',
                )
              "
              :disabled="!canEdit"
              :prefix-disabled="!canEdit"
              prefix-read-only
            >
              <template #prefix>
                <ILxcCalendar class="w-5 h-5 text-gray-700" />
              </template>
              <lxc-option
                v-for="inclusionPeriodOption in inclusionPeriodsOptions"
                :key="inclusionPeriodOption.value"
                class="pl-14"
                :label="inclusionPeriodOption.label"
                :value="inclusionPeriodOption.value"
              />
            </lxc-select>
          </lxc-form-item>
          <div class="flex gap-2 justify-center">
            <p class="text-gray-600 font-semibold">
              {{
                t(
                  "campaign.funnel.campaignPlanification.inclusionPeriods.start",
                )
              }}
            </p>
            <lxc-form-item
              :prop="`periods[${index}].start`"
              :class="hourPickerClasses"
            >
              <lxc-input
                v-model="inclusionPeriod.start"
                type="time"
                class="text-gray-700"
                :style="`width: ${hourPickerWidthInRem}rem`"
                :placeholder="hourPickerPlaceholder"
                prefix-read-only
                prefix-class="!py-0 !top-1/2 -translate-y-1/2"
                :prefix-disabled="!canEdit"
                :disabled="!canEdit"
                @blur.prevent
              />
            </lxc-form-item>
          </div>
          <div class="flex gap-2">
            <p class="text-gray-600 font-semibold">
              {{
                t("campaign.funnel.campaignPlanification.inclusionPeriods.end")
              }}
            </p>
            <lxc-form-item
              :prop="`periods[${index}].end`"
              :class="hourPickerClasses"
            >
              <lxc-input
                v-model="inclusionPeriod.end"
                type="time"
                class="text-gray-700"
                :style="`width: ${hourPickerWidthInRem}rem`"
                :placeholder="hourPickerPlaceholder"
                prefix-read-only
                prefix-class="!py-0 !top-1/2 -translate-y-1/2"
                :prefix-disabled="!canEdit"
                :disabled="!canEdit"
                @blur.prevent
              />
            </lxc-form-item>
          </div>
        </div>
        <div class="flex flex-col justify-center">
          <lxc-button
            v-if="canEdit"
            type="borderless"
            :icon="ILxcTrash2"
            class="!py-0"
            :title="
              t('campaign.funnel.campaignPlanification.inclusionPeriods.delete')
            "
            @click="removeInclusionPeriod(index)"
            @click.prevent
          />
        </div>
      </div>
    </div>
  </lxc-form>
  <lxc-button
    v-if="
      form.periods.length > 0 &&
      form.periods.length < maxInclusionPeriodsInCampaign &&
      canEdit
    "
    type="tertiary"
    :title="t('campaign.funnel.campaignPlanification.inclusionPeriods.add')"
    :icon="ILxcPlusCircle"
    class="mt-3"
    @click="addEmptyInclusionPeriod"
  >
    <span class="font-semibold self-center">{{
      t("campaign.funnel.campaignPlanification.inclusionPeriods.add")
    }}</span>
  </lxc-button>
</template>
