import {
  ApiListResult,
  CampaignOperationMgrCreateI,
  CampaignOperationMgrI,
  CampaignOperationMgrUpdateI,
} from "@lxc/app-device-types";
import RestService from "~/services/rest.service";
import { FiltersSelection } from "~/types";

export class CampaignOperationMgrService extends RestService {
  protected BASE_URL = "/campaignOperationMgr";

  async findWithPagination(
    page: number,
    pageSize: number,
    filter?: string | FiltersSelection,
    sort?: string | null,
  ): Promise<ApiListResult<CampaignOperationMgrI>> {
    const params = { page, pageSize, filter, sort };
    return this.$api.get(this.BASE_URL, { params });
  }

  async find(uid: string): Promise<CampaignOperationMgrI> {
    return this.$api.get(`${this.BASE_URL}/${uid}`);
  }

  async create(
    campaign: CampaignOperationMgrCreateI,
  ): Promise<CampaignOperationMgrI> {
    return this.$api.post(this.BASE_URL, { campaign });
  }

  async update(uid: string, campaign: CampaignOperationMgrUpdateI) {
    return this.$api.patch(`${this.BASE_URL}/${uid}`, {
      updatePayload: campaign,
    });
  }
}
export default new CampaignOperationMgrService();
