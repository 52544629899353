<script setup lang="ts">
import { LicenseStatus } from "@lxc/app-device-types";
import type { DebuggerEventExtraInfo, Ref } from "vue";
import { useAcl } from "vue-simple-acl";
import { PATHS } from "~/constants/paths";
import { useConfigStore } from "~/stores/useConfigStore";
import { useLicenseStatusStore } from "~/stores/useLicenseStatusStore";
import { ACL_ROLES, TabItem } from "~/types";
import { FeaturesName } from "~/types/deviceFeatures";
import ILxcPlus from "~icons/lxc/plus";

const { t } = useI18n();

const route = useRoute();
const router = useRouter();
const acl = useAcl();
const licenseStatusStore = useLicenseStatusStore();

// for S4 local station, redirect to index page when license status is not activated or if feature advanced fleet management is not enabled
licenseStatusStore.$subscribe((mutation, state) => {
  if (route.path === PATHS.CAMPAIGNS_S4_LOCAL_STATION) {
    // the mutation concerns the licenseStatus state and the license status is not activated
    const isLicenseStatusNotActivated =
      (mutation.events as DebuggerEventExtraInfo).key ===
        licenseStatusStore.licenseStateKeys.LICENSE_STATUS &&
      (!state.licenseStatus ||
        state.licenseStatus.statusOfLicense === LicenseStatus.NOT_ACTIVATED);

    // the mutation concerns the license feature advanced fleet management state flag and its value is false
    const isLicenseFeatureAdvancedFleetManagementNotPresent =
      (mutation.events as DebuggerEventExtraInfo).key ===
        licenseStatusStore.licenseStateKeys
          .IS_LICENSE_FEATURE_ADVANCED_FLEET_MANAGEMENT_PRESENT &&
      !state.isLicenseFeatureAdvancedFleetManagementPresent;

    if (
      isLicenseStatusNotActivated ||
      isLicenseFeatureAdvancedFleetManagementNotPresent
    ) {
      router.replace("/");
    }
  }
});

const canManageCampaign = computed(() => acl.can(ACL_ROLES.MANAGE_CAMPAIGNS));

const disableNewCampaign = computed(() => {
  switch (route.path) {
    case PATHS.CAMPAIGNS_S4_LOCAL_STATION: {
      const { isLicenseFeatureAdvancedFleetManagementActive } =
        useLicenseStatusStore();
      return (
        !isLicenseFeatureAdvancedFleetManagementActive ||
        isBeyondLicenseDateValidity.value ||
        isBeyondLicenseLimitOfAllDeviceTypes.value
      );
    }
    default:
      return false;
  }
});

// License Check
const isBeyondLicenseDateValidity: Ref<boolean | undefined> = ref(true);
const isBeyondLicenseLimitOfAllDeviceTypes: Ref<boolean | undefined> =
  ref(true);
const checkLicenseValidityDate = (arg: boolean | undefined) =>
  (isBeyondLicenseDateValidity.value = arg);
const checkLimitOfAllDeviceTypes = (arg: boolean | undefined) =>
  (isBeyondLicenseLimitOfAllDeviceTypes.value = arg);

const { isFeatureCampaignDtwinsEnabled } = useConfigStore();

// tabs management
const tabsItems: Array<TabItem> = [];

if (isFeatureCampaignDtwinsEnabled) {
  tabsItems.push({
    label: t("layout.menu.dtwins.label"),
    to: PATHS.CAMPAIGNS_DEVICES,
    can: ACL_ROLES.DISPLAY_CAMPAIGNS,
  });
}

tabsItems.push({
  label: t("layout.menu.devices"),
  to: PATHS.CAMPAIGNS_S4_LOCAL_STATION,
  can: ACL_ROLES.DISPLAY_CAMPAIGNS,
});

const displayableTabsItems = computed(() => {
  let displayableTabsItems = tabsItems.filter((tabItem) =>
    acl.can(tabItem.can),
  );

  // remove S4 locale station if license status is not activated or if feature advanced fleet management is not enabled
  const { licenseStatus, isLicenseFeatureAdvancedFleetManagementPresent } =
    useLicenseStatusStore();
  if (
    !isLicenseFeatureAdvancedFleetManagementPresent ||
    (licenseStatus &&
      licenseStatus.statusOfLicense === LicenseStatus.NOT_ACTIVATED)
  ) {
    displayableTabsItems = tabsItems.filter(
      (tabItem) => tabItem.to !== PATHS.CAMPAIGNS_S4_LOCAL_STATION,
    );
  }

  return displayableTabsItems;
});

if (displayableTabsItems.value.length === 0) {
  router.replace("/");
}

const selectedTabIndex = computed(() =>
  displayableTabsItems.value.findIndex((tabItem) => tabItem.to === route.path),
);

const showCampaignFunnel: Ref<boolean> = ref(false);
const showCampaignOperationMgrFunnel: Ref<boolean> = ref(false);
function showNewCampaignFunnel() {
  switch (route.path) {
    case PATHS.CAMPAIGNS_S4_LOCAL_STATION:
      showCampaignFunnel.value = true;
      break;
    case PATHS.CAMPAIGNS_DEVICES:
      showCampaignOperationMgrFunnel.value = true;
      break;
  }
}
</script>

<template>
  <lxc-container class="mb-4">
    <div class="px-8">
      <lxc-license-warning-messages
        :context="FeaturesName.ADVANCED_FLEET_MGT"
        @is-beyond-expiration-date="checkLicenseValidityDate($event)"
        @is-beyond-limit-of-all-device-type="checkLimitOfAllDeviceTypes($event)"
      />
    </div>
    <div class="flex justify-between mb-8">
      <lxc-breadcrumb />
      <lxc-button
        v-if="canManageCampaign"
        :icon="ILxcPlus"
        class="h-11"
        size="large"
        type="primary"
        :title="t('campaign.newCampaign')"
        :disabled="disableNewCampaign"
        @click="showNewCampaignFunnel"
      >
        {{ t("campaign.newCampaign") }}
      </lxc-button>
      <lxc-campaign-operation-mgr-funnel
        v-model:show="showCampaignOperationMgrFunnel"
      />
      <lxc-campaign-funnel v-model:show="showCampaignFunnel" />
    </div>
    <lxc-tabs
      v-if="displayableTabsItems.length > 1"
      disable-tab-pane-click
      :initial-selected-tab-index="selectedTabIndex"
      @on-tab-selected="(_, name) => router.push(name)"
    >
      <lxc-tab-pane
        v-for="(tabItem, index) in displayableTabsItems"
        :key="index"
        :label="tabItem.label"
        :name="tabItem.to"
      >
        <router-view v-if="route.path === tabItem.to" />
      </lxc-tab-pane>
    </lxc-tabs>
    <router-view v-else />
  </lxc-container>
</template>
