import type { SlideMenuItem } from "@lxc/app-device-common";
import type LxcError from "~/utils/LxcError";

export type FilterSelectionValue = string | string[];

export type filterOperator =
  | "="
  | "<="
  | ">="
  | "=="
  | "=like="
  | "=ilike="
  | "=in="
  | "=out="
  | "=arct="
  | "in=fleet="
  | "out=fleet=";

export const OPERATOR_EQUAL: filterOperator = "=";
export const OPERATOR_LOWER_OR_EQUAL: filterOperator = "<=";
export const OPERATOR_GREATER_OR_EQUAL: filterOperator = ">=";
export const OPERATOR_RSQL_EQUAL: filterOperator = "==";
export const OPERATOR_RSQL_ARRAY_CONTAINS: filterOperator = "=arct=";
export const OPERATOR_RSQL_LIKE: filterOperator = "=like=";
export const OPERATOR_RSQL_ILIKE: filterOperator = "=ilike=";
export const OPERATOR_RSQL_IN: filterOperator = "=in=";
export const OPERATOR_RSQL_OUT: filterOperator = "=out=";
export const CUSTOM_IN_FLEET_RSQL_OPERATOR: filterOperator = "in=fleet=";
export const CUSTOM_OUT_FLEET_RSQL_OPERATOR: filterOperator = "out=fleet=";

export interface FilterSelectionDefinition {
  key: string | string[];
  operator: filterOperator;
  value: FilterSelectionValue;
  fallbackValue?: FilterSelectionValue;
  valueFormatter?: (value: FilterSelectionValue) => FilterSelectionValue;
}

export type FiltersSelection = Map<Filters, FilterSelectionDefinition>;

export interface Option {
  label: string;
  value: string;
  disabled?: boolean;
}

export interface FilterOptions {
  label?: string;
  options: Option[];
}

export enum Filters {
  KEYSTORE_ALIAS = "keystoreAlias",
  TRUSTSTORE_ALIAS = "truststoreAlias",
  NAME = "name",
  SECTORS = "sectors",
  CONNECTIVITY = "connectivityState",
  STATE = "state",
  STATUS = "status",
  MODEL_TYPE = "lxcModelType",
  MODEL_DECLINATION = "lxcModelDeclination",
  CERTIFICATE_EXPIRE_BEFORE = "certificateExpireBefore",
  CERTIFICATE_EXPIRE_AFTER = "certificateExpireAfter",
  FIRMWARE_NAME = "firmwareName",
  FIRMWARE_NAME_OR_VERSION = "firmwareNameOrVersion",
  RANGE = "range",
  DATE_ADDED_BEFORE = "dateAddedBefore",
  DATE_ADDED_AFTER = "dateAddedAfter",
  FIRMWARE_VERSIONS = "firmwareVersions",
  HARDWARE_VERSIONS = "hardwareVersions",
  APPLICATION_NAME = "applicationName",
  APPMGT_DEVICE_TYPES = "appmgtDeviceTypes",
  APPMGT_DATE_ADDED_BEFORE = "appmgtDateAddedBefore",
  APPMGT_DATE_ADDED_AFTER = "appmgtDateAddedAfter",
  APPMGT_DATE_UPDATED_BEFORE = "appmgtDateUpdatedBefore",
  APPMGT_DATE_UPDATED_AFTER = "appmgtDateUpdatedAfter",
  DEVICE_FIRMWARE_VERSIONS = "deviceFirmwareVersions",
  DEVICE_FIRMWARE_VERSION_INCLUDED_OR_NULL = "firmwareVersionIncludedOrNull",
  DEVICE_HARDWARE_VERSIONS = "deviceHardwareVersions",
  DTWINS_IN_LIFE_CYCLE_STATE = "dtwinInLifeCycleState",
  DTWIN_IN_SECTORS = "dtwinInSectors",
  DTWIN_NOT_IN_SECTORS = "dtwinNotInSectors",
  DTWIN_OPERATION_UID = "deviceTwinOperationUid",
  DTWIN_OPERATION_TYPE = "deviceTwinOperationType",
  DTWIN_OPERATION_SERVICE_ORIGIN = "deviceTwinOperationServiceOrigin",
  DTWIN_OPERATION_MODEL_TYPE = "deviceTwinOperationModelType",
  DTWIN_OPERATION_MODEL_DEVICE_TYPE = "deviceTwinOperationModelDeviceType",
  DTWIN_MODEL_TYPE = "deviceTwinModelType",
  DTWIN_NAME_OR_SERIAL_NUMBER = "deviceTwinNameOrSerialNumber",
  DTWIN_UID = "deviceTwinUid",
  DTWINS_IN_FLEET = "deviceTwinsInFleet",
  DTWINS_NOT_IN_FLEET = "deviceTwinsNotInFleet",
  DTWIN_IN_SOFTWARE_VERSION = "deviceTwinsInSoftwareVersion",
  DTWIN_IN_HARDWARE_VERSION = "deviceTwinsInHardwareVersion",
  KEYSTORE_TAGS = "keystoreTags",
  TRUSTSTORE_TAGS = "truststoreTags",
  LOG_ACTION = "action",
  LOG_TYPE = "type",
  LOG_START_DATE = "startDate",
  LOG_END_DATE = "endDate",
  LOG_COMPONENT_ID = "componentId",
  LOG_ENTITY_CLASS = "entityClass",
  LOG_ENTITY_ID = "entityId",
  LOG_EVENT_TYPE = "eventType",
  LOG_LEVEL = "level",
  LOG_ORIGINS = "origins",
  SORT_BY = "sortBy",
  SORT_DIRECTION = "sortDirections",
  FLEET_NAME = "fleetName",
  OPERATION_CAMPAIGN_UID = "operationCampaignUid",
}

export enum FiltersType {
  FILTERS_SELECTION,
  MICROSOFT_FORMAT,
  PIPE_SEPARATOR,
  RAW,
  RSQL,
  TWO_AMPERSAND_SEPARATOR,
}

export enum FilterInputType {
  RADIO,
  CHECKBOX,
  PERIOD_PICKER,
  CUSTOM,
}

export interface FilterFormSection extends SlideMenuItem {
  additionalFilter?: Filters; // Filter for the end date for example
  emptyOptionsText?: string;
  filter: Filters;
  inputType: FilterInputType;
  loading?: Ref<boolean>;
  loadingError?: Ref<LxcError | undefined | null>;
  options?: Ref<Array<Option> | Array<Record<string, any>>>; // the object must have an id and label property
  tagPrefix: string;
  translate?: boolean; // if the translation is to be done in the LxcFilter component and already provided in the LxcOptions. It can be necessary to set to false if the options list are readonly (Computed).
}
